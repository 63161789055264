<template>
  <v-container v-if='LOGGED_IN' id='page' class='pt-0'>

    <Education>
      Bundling modules together makes it easy to give users access to different modules.<br>
    </Education>

    <v-row>
      <v-col>
        <v-btn class='mb-5 primary' @click='clickNewGroup'><v-icon>mdi-plus</v-icon>New Group</v-btn>
      </v-col>
    </v-row>


    <div id='pageMain'>
      <div id='scrollArea' ref='scrollArea' @mousemove='mouseMove' :class='{dragging}'>
          <div v-for='g in groupTableIndex' v-bind:key='g' class='Col'>
            <div v-for='m in moduleTableIndex' v-bind:key='m' class='Row'>

              <div class='fillCell'>

                <!-- TOP CORNER -->
                <div v-if='m == -1 && g == -1' class='centerMe' style='justify-content:flex-start'>
                  <div class='ml-2 white--text header-font' style='display: block; text-overflow:ellipsis; overflow: hidden; white-space: nowrap;'>
                    Permission
                  </div>
                </div>
                <!-- TOP HEADERS -->
                <div v-else-if='m == -1'
                  class='centerMe groupLabel text-center header-font'>
                  <div style='display: block; text-overflow:ellipsis; overflow: hidden; white-space: nowrap;' @click='clickModuleName(g)'>
                    {{groups[g].title}}
                  </div>
                </div>
                <!-- LEFT HEADERS -->
                <div v-else-if='g == -1'
                  class='centerMe leftHeaders font-weight-medium'
                  style='justify-content:flex-start;'>
                  {{modules[m].title}}
                </div>
                <!-- Others -->
                <div class='centerMe' v-else>
                  <TickBox :module='modules[m]' :group='groups[g]'/>
                </div>

              </div>


            </div>
          </div>
        </div>
    </div>

  </v-container>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import Education from '@/components/Education'
import TickBox from '@/components/ModuleGroups/TickBox'

export default {
  name: 'Permissions',

  components: {
    Education, TickBox
  },

  data: () => ({
    groups: [],
    modules: [],
    dragging: false,
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN']),

    //computes an array from -1 to last module index
    moduleTableIndex(){
      let index = [];
      for(let i=-1; i<this.modules.length; i++){ index.push(i); }
      return index;
    },

    //computes an array from -1 to last module index
    groupTableIndex(){
      let index = [];
      for(let i=-1; i<this.groups.length; i++){ index.push(i); }
      return index;
    },


  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),

    mouseMove(e){
      if(e.buttons == 1){
        this.dragging = true;
        let scrollArea = this.$refs['scrollArea'];
        scrollArea.scrollTop -= e.movementY;
        scrollArea.scrollLeft -= e.movementX;
      }
      else this.dragging = false;
    },

    loadModules(){
      return this.API({ endpoint: 'core/auth/user_modules', data: { token: this.TOKEN } })
      .then((data)=> {
        this.modules = data;
      });
    },

    loadPermissionGroups(){
      return this.API({ endpoint: 'core/auth/permissions', data: { token: this.TOKEN } })
      .then((data)=> {
        this.groups = data;
      });
    },

    clickNewGroup(){
      this.PROMPT({ message: 'What is the name of the new module group?', title: 'New Module Group' })
      .then(name => {
        this.API({ endpoint: 'core/auth/permissions/new', data: {
          token: this.TOKEN,
          name: name,
        }})
        .then(this.loadPermissionGroups)
        .catch(()=>{ /* API ERROR */ });
      })
      .catch(()=>{ /* Clicked Cancel */ });
    },

    clickModuleName(g){
      this.PROMPT({
        title: 'Rename Module Group',
        message: 'Please enter a name for the group.\nYou may delete the group by leaving this blank.',
        placeholder: this.groups[g].title
      })
      .then(newName => {
        if(newName == '') this.deleteGroup(g); //delete
        else if(newName == this.groups[g].title) return; //no change
        else this.renameGroup(g, newName);
      })
      .catch(()=>{ /* Clicked Cancel */ });
    },

    deleteGroup(g){
      let permissionId = this.groups[g].xid;
      this.API({ endpoint: 'core/auth/permissions/delete', data: {
        token: this.TOKEN,
        permission: permissionId,
      }})
      .then(()=>{
        this.groups = this.groups.filter(group => group.xid != permissionId);
      })
      .catch(()=>{ /* Changes probably failed - don't update state */ });
    },

    renameGroup(g, name){
      this.API({ endpoint: 'core/auth/permissions/rename', data: {
        token: this.TOKEN,
        permission: this.groups[g].xid,
        name: name
      }})
      .then(()=>{
        this.groups[g].title = name;
      })
      .catch(()=>{ /* Changes probably failed - don't update label */ });
    },

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.loadModules()
      .then(this.loadPermissionGroups)
      .catch(e => {
        console.log("Mount Error:", e);
      });
    });
  },

};
</script>


<style lang="scss" scoped>
@import "@/assets/common";

$devMode: false;
$devMargin: if($devMode, 2px, none);

$topHeight: 48px;
$topWidth: 150px;
$topRadius: 6px;
$leftWidth: 200px;
$leftHeight: 50px;

#page{
  //a full height layout that has vertical sections
  height: 100%;
  display:flex;
  flex-direction: column;
}

#pageMain{
  //grow the lower section of the page
  background-color: if($devMode, red, none);
  position: relative;
  height: 100%;
}

#scrollArea{
  //an area which all children will be scrollable within
  background-color: if($devMode, yellow, none);
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: absolute;
  display: flex;
  align-items: baseline;
  cursor: grab;
  user-select: none;
}

.header-font{
  font-weight: 700;
  font-size: 0.75rem;
}

.Col{
  background-color: if($devMode, green, none);;
  margin: $devMargin;
  width: $topWidth;
  float: left;
  flex-shrink: 0;
  position: relative;
  &:first-of-type{
    width: $leftWidth;
    background-color: if($devMode, lime, none);;
    position: sticky;
    left: 0px;
    z-index: 2;
  }
}

.Row{
  margin: $devMargin;
  background-color: if($devMode, lightblue, none);;
  width: 100%;
  height: $leftHeight;
  float: left;
  flex-shrink: 0;
  position: relative;
  &:first-of-type{
    height: $topHeight;
    background-color: if($devMode, cyan, none);;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
}

.fillCell{
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}

.centerMe{
  height: 100%;
  display:flex;
  align-items:center;
  justify-content: center;
  padding: 5px;
}

.tickBox{
  .v-input__slot {
    align-items: center;
    justify-content: center;
  }
}

.hoverRowCol{
  &:after{
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($secondary, 0.1);;
    position: absolute;
    content: '';
    margin: -5px;
    border-radius: 4px;
  }
}

*::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  background-color: transparent;
  &-thumb{
    border-radius: 10px;
    background-color: rgba($secondary, 0.5);
    &:hover{
      background-color: rgba($secondary, 1);
    }
  }
}

.dragging{ cursor: grabbing !important; }

.groupLabel{
  cursor: pointer;
  color: white;
}

.leftHeaders{
  color: $secondary;
  padding-left: 12px;
}

.Row:first-of-type{
  background-color: $primary;
  .fillCell{ background-color: transparent; }
  margin-bottom: 12px;
}

.Col:first-of-type{
  .Row:first-of-type{
    border-top-left-radius: $topRadius;
    border-bottom-left-radius: $topRadius;
  }
}

.Col:last-of-type{
  .Row:first-of-type{
    border-top-right-radius: $topRadius;
    border-bottom-right-radius: $topRadius;
  }
}


</style>
