<template>
  <v-checkbox class='centerMe ml-2' @click='click' v-model='tick' :disabled='loading' />
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.loading, .loading.v-input--selection-controls__ripple {
  cursor: wait !important;
  background-color: red;
}

</style>

<script>
import vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TickBox',

  components: {
  },

  props: {
    module: { type: Object },
    group: { type: Object },
  },

  data: () => ({
    tick: false,
    loading: false,
  }),

  computed: {
        ...mapGetters('Auth', ['TOKEN']),
  },

  methods:{
    ...mapActions('API', ['API']),

    click(){
      if(this.tick) this.addModule();
      else this.removeModule();
    },

    addModule(){
      this.loading = true;
      this.API({
        endpoint: 'core/auth/permissions/enable',
        data: {
          token: this.TOKEN,
          permission: this.group.xid,
          module: this.module.xid,
        }
      })
      .then(()=>{
        this.loading = false;
        this.group.modules.push(this.module);
        this.updateTickBox();
      })
      .catch(()=>{
        this.loading = false;
        this.updateTickBox();
      });
    },

    removeModule(){
      this.loading = true;
      this.API({
        endpoint: 'core/auth/permissions/disable',
        data: {
          token: this.TOKEN,
          permission: this.group.xid,
          module: this.module.xid,
        }
      })
      .then(()=>{
        this.loading = false;
        this.group.modules = this.group.modules.filter(m => m.code != this.module.code);
        this.updateTickBox();
      })
      .catch(()=>{
        this.loading = false;
        this.updateTickBox();
      });
    },

    updateTickBox(){
      vue.nextTick(()=>{
        this.tick = this.group.modules.map(m => m.code).includes(this.module.code);
      });
    }
  },

  mounted() {
    this.updateTickBox();
  },

};
</script>
