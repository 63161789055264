var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    staticClass: "pt-0",
    attrs: {
      "id": "page"
    }
  }, [_c('Education', [_vm._v(" Bundling modules together makes it easy to give users access to different modules."), _c('br')]), _c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "mb-5 primary",
    on: {
      "click": _vm.clickNewGroup
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New Group")], 1)], 1)], 1), _c('div', {
    attrs: {
      "id": "pageMain"
    }
  }, [_c('div', {
    ref: "scrollArea",
    class: {
      dragging: _vm.dragging
    },
    attrs: {
      "id": "scrollArea"
    },
    on: {
      "mousemove": _vm.mouseMove
    }
  }, _vm._l(_vm.groupTableIndex, function (g) {
    return _c('div', {
      key: g,
      staticClass: "Col"
    }, _vm._l(_vm.moduleTableIndex, function (m) {
      return _c('div', {
        key: m,
        staticClass: "Row"
      }, [_c('div', {
        staticClass: "fillCell"
      }, [m == -1 && g == -1 ? _c('div', {
        staticClass: "centerMe",
        staticStyle: {
          "justify-content": "flex-start"
        }
      }, [_c('div', {
        staticClass: "ml-2 white--text header-font",
        staticStyle: {
          "display": "block",
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap"
        }
      }, [_vm._v(" Permission ")])]) : m == -1 ? _c('div', {
        staticClass: "centerMe groupLabel text-center header-font"
      }, [_c('div', {
        staticStyle: {
          "display": "block",
          "text-overflow": "ellipsis",
          "overflow": "hidden",
          "white-space": "nowrap"
        },
        on: {
          "click": function click($event) {
            return _vm.clickModuleName(g);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.groups[g].title) + " ")])]) : g == -1 ? _c('div', {
        staticClass: "centerMe leftHeaders font-weight-medium",
        staticStyle: {
          "justify-content": "flex-start"
        }
      }, [_vm._v(" " + _vm._s(_vm.modules[m].title) + " ")]) : _c('div', {
        staticClass: "centerMe"
      }, [_c('TickBox', {
        attrs: {
          "module": _vm.modules[m],
          "group": _vm.groups[g]
        }
      })], 1)])]);
    }), 0);
  }), 0)])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }