var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-checkbox', {
    staticClass: "centerMe ml-2",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.click
    },
    model: {
      value: _vm.tick,
      callback: function callback($$v) {
        _vm.tick = $$v;
      },
      expression: "tick"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }